import React from 'react'
import './Trends.css'
const Trends = () => {
    return (
        <div className='trends'>
            <div className="banner_contain">
                <h2>Latest</h2>
                <h3>fashion trends</h3>
                <h4>special offer</h4>
            </div>
        </div>
    )
}

export default Trends