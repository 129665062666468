import React, { Suspense } from 'react'
import Navbottom from './Navbottom'
import Navtop from './Navtop'

const MainNav = () => {

  return (
    <>
      <Navtop />
      <Navbottom /> 
    </>
  )
}

export default MainNav